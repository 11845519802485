<template>
  <div v-show="isAllowed" @click="openModal" class="btn btn-primary--inverse">
    <img class="mr-2" src="@/assets/img/icons/cancel.svg" />
    <span>{{ buttonName }}</span>
  </div>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';

export default {
  name: 'tour-reject-booking-btn',
  props: {
    allowedActions: {
      required: true,
      validator: (value) => {
        if (value !== null) {
          return value.constructor.name === 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    bookingInfo: {
      required: true,
    },
  },
  mixins: [ButtonMixin],
  data() {
    return {
      actionName: 'CANCEL_BOOK',
      actionType: 'TOUR_PROG',
    };
  },
  methods: {
    openModal() {
      this.$modal.show('rent-reject-modal', {
        uuid: this.bookingInfo.rent_transaction_uuid,
        buttonName: this.buttonName,
      });
    },
  },
};
</script>

<style scoped></style>
